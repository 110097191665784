import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CreateProject from "./components/Projects/CreateProject";
import ListProject from "./components/Projects/Listprojects";
import Projects from "./components/ABTesting/CreateCampaigns";
import Variations from "./components/ABTesting/Variations";
import ListCampaign from "./components/ABTesting/Listcampaigns";
import CampaignReporting from "./components/Reporting/campaignReport";
import Table from "./components/Users/Table";
import CreateUser from "./components/Users/CreateUser";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Users/Profile";
import CampaignReporting2 from "./components/Reporting/campaignReport2";
import CampaignReporting3 from "./components/Reporting/campaignReport3";


const router = createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute><App /></PrivateRoute>,
      errorElement: <App />,
      children: [
        // {
        //   path: "/",
        //   element: <PrivateRoute><Dashboard/></PrivateRoute>,
        // },
        {
          path: "dashboard",
          element: <PrivateRoute><Dashboard/></PrivateRoute>,
        },
        {
          path: "projects",
          element: <PrivateRoute><ListProject/></PrivateRoute>,
        },
        {
          path: "project/:projectId",
          element: <PrivateRoute><CreateProject /></PrivateRoute>,
        },
        {
          path: "project/create",
          element: <PrivateRoute><CreateProject isEdit /></PrivateRoute>,
        },
        
        {
          path: "users/create",
          element: <PrivateRoute><CreateUser/></PrivateRoute>,
          
        },
        {
          path: "users/:userId",
          element: <PrivateRoute><CreateUser/></PrivateRoute>,
          
        },
        {
          path: "users/table",
          element: <PrivateRoute><Table/></PrivateRoute>,
          
        },
        {
          path: "ab-testing",
          element: <PrivateRoute><ListCampaign /></PrivateRoute>,
          
        },
        {
          path: "campaign/:campaignId",
          element: <PrivateRoute><Projects /></PrivateRoute>,
        },
        {
          path: "ab-testing/create",
          element: <PrivateRoute><Projects /></PrivateRoute>,
          
        },
        {
          path: "variations",
          element: <PrivateRoute><Variations /></PrivateRoute>,
          
        },
        {
          path: "reporting/:campaignId",
          element: <PrivateRoute><CampaignReporting3/></PrivateRoute>,
          
        },
        {
          path: "/profile/",
          element: <PrivateRoute><Profile /></PrivateRoute>,

        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <Login />,
    },
    {
      path: "/login/:loginMessage",
      element: <Login />,
      errorElement: <Login />,
    },
  ])

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <RouterProvider router={router} />
  
);

reportWebVitals();
