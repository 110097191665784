import React from "react";
import ReportingIcon from "../../icons/reportingIcon";
import PauseIcon from "../../icons/PauseIcon";
import StartIcon from "../../icons/StartIcon";
import { getLocalStorage } from "../../constants/utility";
import VerticalThreeDots from "../../icons/VerticalThreeDots";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import RotatingLoader from "../RotatingLoader";

const CSVPostString= moment(new Date()).format("DD/MM/YYYY")
const ReportHeaderSection = ({
  handleCampaignStatus,
  loading,
  campaignValue,
  setIsOpen,
  campaignId,
  data,
  headers,
  summaryData,
  summaryHeaders,
  handleRefreshClick,
  refreshLoader,
}) => {

  const isViewer = getLocalStorage("role") === "viewer" ? true : false;
  const navigate = useNavigate();
  return (
    <div className="grid bg-gray-100 p-5 pt-20">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {campaignValue?.campaignKey}
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 justify-between">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ReportingIcon />
              Reporting for campaigns
            </div>
            <div>
              <span className="sm:ml-3 space-x-3">
                <RotatingLoader handleRefreshClick={handleRefreshClick} refreshLoader={refreshLoader} />
                <button
                  onClick={handleCampaignStatus}
                  type="button"
                  disabled={isViewer === false ? false : true}
                  className="inline-flex items-center  h-9 rounded-md bg-indigo-600 px-5 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 transition-transform duration-300 ease-in-out transform hover:scale-105"
                >
                  {loading ? (
                    // Loader SVG or any other loading indicator
                    <svg aria-hidden="true" className="inline w-6 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg> 
                  ) : 
                  (
                    // Button text and icon for active/inactive state
                    <>
                      {campaignValue.status === "active" ? (
                        <>
                          <PauseIcon /> Pause
                        </>
                      ) : (
                        <>
                          <StartIcon /> Start
                        </>
                      )}
                    </>
                  )}
                </button>
                <div className="dropdown relative inline-block">
                  <button className="dropdown-toggle inline-flex h-9 items-center rounded-md border border-transparent bg-indigo-600 px-1 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 ">
                    <VerticalThreeDots />
                  </button>
                  <ul className="dropdown-menu hidden absolute z-10 bg-white text-gray-800 rounded-md shadow-md py-1  right-0">
                   {
                     isViewer === false && (<> 
                        <li
                          onClick={() => setIsOpen(true)}
                          className="dropdown-item cursor-pointer hover:bg-gray-100 py-1 px-7">
                          Delete
                        </li>
                        <li
                          onClick={() => navigate(`/campaign/${campaignId}`)}
                          className="dropdown-item cursor-pointer hover:bg-gray-100 py-1 px-7">
                          Update
                        </li>
                      </>
                     )
                   }
                    <CSVLink data={data} filename={`Export_Summary-${CSVPostString}`}  headers={headers}>
                      <li className="dropdown-item cursor-pointer hover:bg-gray-100 py-1 px-7">
                        Export Summary
                      </li>
                    </CSVLink>
                    <CSVLink data={summaryData} filename={`Details_Summary-${CSVPostString}`} headers={summaryHeaders}>
                      <li className="dropdown-item cursor-pointer hover:bg-gray-100 py-1 px-7">
                        Details Summary
                      </li>
                    </CSVLink>
                  </ul>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeaderSection;
