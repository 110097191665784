import { PencilIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
const Variations = () => {
    return (
        <>
            <div className="grid bg-gray-100 p-5 pt-20">
                {/*  project heading  */}

                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Campaign
                        </h2>
                        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                {/*  Heroicon name: mini/briefcase  */}
                                <svg
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"
                                        clipRule="evenodd"
                                    />
                                    <path d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z" />
                                </svg>
                                New A/B Test Campaign
                            </div>
                        </div>
                    </div>
                </div>

                {/*  project heading end */}
            </div>
            <div className="grid grid-cols-3">
                <div className="col-span-2 p-5">
                    <div className="lg:flex flex-col">
                        <div className="min-w-0 flex-1 pb-5">
                            <h4 className="text-xl font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                                Create variations
                            </h4>
                            <div className="grid grid-cols-2 gap-10">
                                <div className="col-span-2">
                                    <div className="flex flex-col bg-white border rounded-md px-5">
                                        <div className="flex flex-row p-2 border-b-2 text-sm font-bold">

                                            Variations
                                        </div>
                                        <div className="flex felx-row items-center flex-row p-2 border-b-2">
                                            <span className="h-10 w-10 mr-2 rounded-3xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold flex justify-center items-center">co </span>
                                            Control
                                            <span><PencilIcon className="pl-2 h-5 w-5" />
                                            </span>
                                        </div>

                                        
                                        <div className="flex flex-row p-2">
                                            <span className="h-10 w-10 mr-2 rounded-3xl bg-gradient-to-r from-blue-500 to-green-500 text-white font-bold flex justify-center items-center">Va </span>
                                            Variation-1
                                            <span>
                                                <PencilIcon className="pl-2 h-5 w-5" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="min-w-0 flex-1 pb-5">
                            <h4 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                                Code for Campaign 1
                            </h4>
                            <div className="grid grid-cols-2 gap-10 py-5">
                                <div className="col-span-2">
                                    <div className="flex bg-gray-300 rounded-lg p-5">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="min-w-0 flex pb-5 justify-end">
                            <Link to="new_project.html">
                                <button
                                    type="button"
                                    className="items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Create
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-span-1 relative bg-gray-200 p-5 min-h-[calc(100vh-170px)]">
                    <h3 className="text-lg font-bold text-gray-900 pb-5 sm:text-2xl">
                        World's Easiest A/B Testing Platform
                    </h3>
                    <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="my-2 flex-shrink text-sm text-gray-500">
                            <p>Easily create multiple variations in your Campaign. VWO will equally divide your website traffic among all the variations and track which one works the best for you.</p>
                        </div>
                    </div>
                    <div className="my-1 flex sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="mt-2 flex  text-sm text-gray-500">
                            <span className="flex">image</span>
                        </div>
                    </div>
                    <div className="my-2 flex-shrink text-sm text-gray-500">
                        <ul className="list-disc px-5">
                            <li className="pb-2">Create multiple variations of a feature</li>
                            <li className="pb-2">Track revenue of any other conversion goal</li>
                            <li className="pb-2">Know the statical validity of the results</li>
                        </ul>
                    </div>
                    <div className="my-1 flex sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="mt-2 flex  text-sm text-gray-500">
                            <span className="flex">To know more, read our popular Feature testing guide.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Variations;