import {PlusSmallIcon} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";

const NewProject = () => {

  return (
    <React.Fragment>
<div className="grid bg-gray-100 p-5 pt-20">
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Projects
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <svg className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd"
                d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"
                clipRule="evenodd" />
              <path
                d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z" />
            </svg>
            Build projects to manage your campaigns
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
       
        <div className="relative ml-3 sm:hidden">
          <button type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            id="mobile-menu-button" aria-expanded="false" aria-haspopup="true">
            More
            <svg className="-mr-1 ml-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd" />
            </svg>
          </button>
          <div
            className="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="mobile-menu-button" tabIndex="-1">
            <Link className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1"
              id="mobile-menu-item-0">Edit</Link>
            <Link className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1"
              id="mobile-menu-item-1">View</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div className="grid grid-cols-3">
        <div className="col-span-2 p-5">
          <div className="flex flex-col min-h-[calc(100vh-210px)] items-center justify-center">
            <h4 className="text-2xl font-bold tracking-tight text-gray-900 py-2">No Projects have been created yet.
            </h4>
           
              <Link
                to="/project/create"
                className="items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                Create your First Project.
              </Link>
           
          </div>
        </div>
        <div className="col-span-1 relative bg-gray-200 p-5 min-h-[calc(100vh-170px)]">
        <div className="block pb-5">
          <div className="relative mt-1 rounded-md shadow-sm"><input type="search" name="Search"
            className="block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Search" />
          </div>
        </div>
        <div className="block pb-5">
        <label htmlFor="price" className="block text-sm font-medium text-gray-700">Short By</label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <select id="currency" name="currency" className="block w-full px-2 py-3 rounded-md border-gray-300 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <option>Select Project Name</option>
            <option>Create Time</option>
            <option>Project Time</option>
            <option>Creator Time</option>
            <option>Modification Time</option>
            </select>
          </div>
        </div>
        <div className="block pb-5">
        <label htmlFor="price" className="block text-sm font-medium text-gray-700">Filter by Languages</label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <select id="currency" name="currency" className="block w-full px-2 py-3 rounded-md border-gray-300 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <option>Select Project Name</option>
            <option>Node Js</option>
            <option>Python</option>
            <option>Php</option>
            <option>Java</option>
            </select>
          </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewProject;
