import axios from "axios";
export const TRACKING_URL = `http://localhost:3000/tracking`;


const axiosInstance = axios.create({
  // production
  baseURL: "https://d1s3pd2abf.execute-api.ap-northeast-1.amazonaws.com",
  

  // development
  // baseURL: "https://qk0iq29lha.execute-api.ap-northeast-1.amazonaws.com",

  // local
    // baseURL: "http://localhost:3000",
});


axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired or invalid, redirect to login page
      // window.location = "http://localhost:3001/login";
      window.location = "https://d3kk07h5o8m7e6.cloudfront.net/login";
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
