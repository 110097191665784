export const generateVariationKey=(str)=>
{
    if (str.length === 0) {
        return str;
      }
      
      return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getStringDate=(para)=>
{
    const date = new Date(para).getDate()
    return date
    // return `${date.getDate()}/${
    //   date.getMonth() + 1
    // }/${date.getFullYear()}`;
}

export const getLocalStorage=(parameter)=>{
  const data = JSON.parse(localStorage.getItem("userDetails"))[parameter]
  return data ;
}

export  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  export function getObjectsWithSubgoalIds(items) {
    const idMap = new Map();
  
    items.forEach(item => {
      const uniqueSubgoalId=`${item.uniqueId}-${item.subgoalId}-${item.goal}`
        if (item && item.uniqueId) {
            idMap.set(uniqueSubgoalId, item);
        }
    });
  
    return Array.from(idMap.values());
}
  export function getObjectsWithUniqueIds(items) {
    const idMap = new Map();
  
    items.forEach(item => {
        if (item && item.uniqueId) {
            idMap.set(item.uniqueId, item);
        }
    });
  
    return Array.from(idMap.values());
}

export const Capitalize=(val)=>
{
   return val?.charAt(0).toUpperCase() + val.slice(1)
}