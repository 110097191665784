import { getLocalStorage } from '../../constants/utility';

const Cards = ({ cardData }) => {
  const shouldRenderFourthCard = getLocalStorage("role");

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
      <div className="bg-custom-green p-5 rounded-lg">
        <p className="text-black font-semibold">Total Campaigns</p>
        <h3 className="text-2xl text-black font-bold">{cardData.totalCampaigns}</h3>
      </div>
      <div className="bg-custom-yellow p-5 rounded-lg">
        <p className="text-black font-semibold">Total Projects</p>
        <h3 className="text-2xl text-black font-bold">{cardData.totalProjects}</h3>
      </div>
      <div className="bg-custom-pink p-5 rounded-lg">
        <p className="text-black font-semibold">Active Campaigns</p>
        <h3 className="text-2xl text-black font-bold">{cardData.activeCampaigns}</h3>
      </div>
      {shouldRenderFourthCard !== 'user' && (
        <div className="bg-custom-purple p-5 rounded-lg">
          <p className="text-black font-semibold">Total Users</p>
          <h3 className="text-2xl text-black font-bold">{cardData.totalUsers}</h3>
        </div>
      )}
    </div>
  );
};

export default Cards;
