// export const headers = [
//     { label: "Goal : ", key: "selectedGoal" },
//     { label: "Variations", key: "variations" },
//     {
//       label: "PV Incidence = Number of PV/total number of searches",
//       key: "pvIncidence",
//     },
//     {
//       label: "Like Rate = Number of likes/total number of searches",
//       key: "likeRate",
//     },
//     {
//       label: "Application Rate = Number of applications/total number of searches",
//       key: "applicationRate",
//     },
//     {
//       label: "Number of users who liked at least one item from the search path Paid",
//       key: "totalUsersWhoLikedAtLeastOneItemFromSearchPathPaid",
//     },
//     {
//       label: "Number of users who applied for at least one job from the search path",
//       key: "totalUsersWhoAppliedForAtLeastOneJobFromSearchPath",
//     },
// ];

export const headers = [
  { label: "Test Group", key: "variation" },
  { label: "Date", key: "date" },
  {
    label: "Goal",
    key: "goal",
  },
  {
    label: "Count",
    key: "count",
  },
];
  
export const summaryHeaders = [
    { label: "CAMPAIGN", key: "campaign" },
    { label: "CREATED AT", key: "createdAt" },
    { label: "UNIQUE ID", key: "uniqueId" },
    { label: "VARIATION", key: "variation" },
    { label: "GOAL", key: "goal" },
    { label: "SUBGOAL ID", key: "subgoalId" },
];