import { Navigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

function PrivateRoute({ children, ...rest }) {

  // Check if token is available in localStorage
  const token = localStorage.getItem('token');

  if (token) {
    // Decode the token to get its expiration time
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds

    // Check if the token has expired
    if (decodedToken.exp < currentTime) {
      // Token has expired, redirect to login route
      return <Navigate to="/login/:session_expired" replace={true} />
    }
  } else {
    // Token is not available, redirect to login route
    return <Navigate to="/login" replace={true} />
  }

  // If token is available and not expired, render the specified children
  return children;
}

export default PrivateRoute;
