import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../apiEndpoints";
import { createCampaign } from "../../services/campaign.service";
import EditIcon from "../../icons/EditIcon";
import BagIcon from "../../icons/BagIcon";
import AddIcon from "../../icons/AddIcon";
import ArrowIcon from "../../icons/ArrowIcon";
import { useNavigate } from "react-router-dom";
import { validationSchema, initialValues } from "../../constant";
import { buttonCss } from "../../css/button";
import { ToastContainer, toast } from "react-toastify";
import { errorToast } from "../../constants/toasts";
import { getLocalStorage } from "../../constants/utility";
import { removeWhiteSpaceAndLowerCase } from "../../utility";

const Projects2 = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [projects, setProjects] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [sdkKey, setSdkKey] = useState("");
  const [environment, setEnvironment] = useState([]);
  const [showStep, setShowStep] = useState("Projects");
  const [editingLabelIndex, setEditingLabelIndex] = useState(null);
  const [allCampaign, setAllCampaign] = useState("");
  const [subEnv, setSubEnv] = useState([]);
  const [errorsVisible, setErrorsVisible] = useState(false);
  const [isCampaignKey, setIsCampaignKey] = useState(true);

  const handleVariationsSliderChange = (event, setFieldValue, index, formValue) => {
    const isControl = index === -1;
    const controlValue = formValue.controlPercent;
    
    let totalVariationsVal = 
            formValue.variations
              .map(data => data.variationsPercent)
              .reduce((acc, curr) => acc + curr, 0);

    totalVariationsVal += controlValue

    const currentVariationInitialValue = isControl ? controlValue : formValue.variations[index].variationsPercent  ;
    const sliderIsIncresingValue = currentVariationInitialValue < event.target.value;
    const totalVariationsValExceptCurrent= totalVariationsVal - currentVariationInitialValue;
    const value = parseInt(event.target.value)
    setFieldValue( isControl ? "controlPercent" : `variations.${index}.variationsPercent`, sliderIsIncresingValue && totalVariationsVal >= 100 ? (100 - totalVariationsValExceptCurrent > 0 ? 100 - totalVariationsValExceptCurrent : 0)  : value);
  }

  const handleChangeCampaignKey = (event, setFieldValue) => {
    const currentCampaignKey = event.target.value;
    setFieldValue("campaignKey", currentCampaignKey);
    const existingCampaign = allCampaign.find((ele) => removeWhiteSpaceAndLowerCase(ele.campaignKey) === removeWhiteSpaceAndLowerCase(currentCampaignKey));
    if (existingCampaign) {
      toast(`${currentCampaignKey} name already been used, try some other name`, errorToast);
      setIsCampaignKey(false);
    } else {
      setIsCampaignKey(true);
    }
  };

  useEffect(() => {
    const getProjectList = async () => {
      try{
        const res=await axiosInstance.get("project/getAllProjects")
        setProjects(res.data.data)
      }catch(err)
      {
        console.log(err)
      }
    };
    getProjectList();
    getAllCampaign();
  }, [environment, isCampaignKey]);

  const getAllCampaign = async () => {
    try{
      const res=await axiosInstance.get("campaign/getAllABTesting")
      setAllCampaign(res?.data.data);
    }catch(err)
    {
      console.log(err)
    }
  };

  useEffect(() => {
    if (!campaignId) return;
    const getCampaignData = async () => {
      try{
        const res=await axiosInstance.get(`campaign/getABTestingById/${campaignId}`)
        setCampaign(res?.data?.data);
      }catch(err)
      {
        console.log(err)
      }
    };
    getCampaignData();
  }, []);

  // ***** Handling the project field ***** //
  const handleChangeProjectName = (event, setFieldValue) => {
    const projectName = event.target.value;
    const particularProject = projects.find((project) => project.projectName === projectName);
  
    if (particularProject) {
      setSelectedProject(particularProject);
      setFieldValue("projectName", projectName);
      const environmentArray = particularProject.environments.map((environment) => environment);
      setSubEnv(environmentArray);
    } else {
      // Handle case when no matching project is found
      setSelectedProject(null);
      setFieldValue("environments", "");
      setFieldValue("projectName", "");
      setSubEnv([]);
    }
  };
  
  // ***** Handling the environment field ***** //
  const handleChangeEnvironmentName = (event, setFieldValue) => {
    const selectedEnvironment = selectedProject.environments.find(
      (e) => e.environmentName === event.target.value
    );
    if (selectedEnvironment) {
      const { environmentName, sdkKey } = selectedEnvironment;
      setFieldValue("environments", environmentName);
      setSdkKey(sdkKey);
    }
    else {
      // Handle case when no matching environment is found
      setFieldValue("environments", '');
      setSdkKey('');
    }
  };

  if (campaignId && !campaign?.id) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Formik
        initialValues={
          campaignId
            ? {
                campaignKey: campaign?.campaignKey,
                projectName: campaign.projectName,
                environments: campaign?.environments,
                controlPercent: campaign?.controlPercent,
                trafficSplit: campaign?.trafficSplit,
                epsilonValue: campaign?.epsilonValue,
                variations: campaign?.variations,
                goals: campaign?.goals,
                gaApiKey: campaign?.gaApiKey,
                abTestType: campaign?.abTestType,
              }
            : initialValues
        }
        validationSchema={validationSchema}
        // Unique goal identifier's name Handling 
        validate={(values) => {
          const errors = {};
          const goalIdentifiers = values.goals.map((goal) => goal.goalIdentifier);
          const uniqueGoalIdentifiers = new Set(goalIdentifiers);
          const goalTitlesLowercase = values.goals.map((goal) => goal.goalTitle?.toLowerCase());
          const uniqueGoalTitlesLowercase = new Set(goalTitlesLowercase);

          if (goalIdentifiers.length !== uniqueGoalIdentifiers.size) {
            errors.goals = errors.goals || [];
            for (let i = 0; i < goalIdentifiers.length; i++) {
              if (goalIdentifiers.indexOf(goalIdentifiers[i]) !== i) {
                errors.goals = [
                  ...(errors.goals || []),
                  {
                    goalIdentifier: 'Goal Identifier must be unique',
                  },
                ];
              } else {
                errors.goals = [...(errors.goals || []), {}];
              }
              // Check for spaces in the goal identifier
              if (goalIdentifiers[i].includes(' ')) {
                errors.goals = [
                  ...(errors.goals || []),
                  {
                    goalIdentifier: 'Goal Identifier should not contain spaces',
                  },
                ];
              }
            }
          }
          if (goalTitlesLowercase.length !== uniqueGoalTitlesLowercase.size) {
            errors.goals = errors.goals || [];
            for (let i = 0; i < goalTitlesLowercase.length; i++) {
              if (goalTitlesLowercase.indexOf(goalTitlesLowercase[i]) !== i) {
                errors.goals = [
                  ...(errors.goals || []),
                  {
                    goalTitle: 'Goal Title must be unique',
                  },
                ];
              } else {
                errors.goals = [...(errors.goals || []), {}];
              }
            }
          }
          return errors;
        }}
        onSubmit={async (data) => {
          if (campaignId) {
            await axiosInstance.put(`campaign/updateABTesting/${campaignId}`, data);
            navigate("/ab-testing");
          } else {
            data.projectId = selectedProject.id;
            data.language = selectedProject.language;
            data.sdk_Key = sdkKey;
            data.status = "inactive";
            await createCampaign(data);
            navigate("/ab-testing");
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid bg-gray-100 p-5 pt-20">
              {/*  project heading  */}
              <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-1">
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    AB Testing
                  </h2>
                  <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 ">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      {/*  Heroicon name: mini/briefcase  */}
                      <BagIcon />
                      New A/B Test Campaign
                    </div>
                  </div>
                </div>
              </div>
              {/*  project heading end */}
            </div>
            <div className="grid grid-cols-3">
              <div className="col-span-2 p-5">
                <div className="lg:flex flex-col ">
                  {showStep === "Projects" && (
                    <div>
                      <div className="min-w-0 flex-1 pb-5">
                        <h4 className="text-xl font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                          Define the campaign key, select hypothesis and test
                          configuration
                        </h4>
                        <div className="grid grid-cols-2 gap-10">
                          <div className="col-span-2">
                            <label className="flex text-sm font-medium text-gray-700">
                              Campaign Key
                              <span className="pl-1">
                                <QuestionMarkCircleIcon className="h-6 w-6" />
                              </span>
                            </label>
                            <div className="relative mt-1 rounded-md shadow-sm">
                              <Field
                                type="text"
                                name="campaignKey"
                                disabled={campaignId ? true : false}
                                value={values.campaignKey}
                                onChange={(event) => {
                                  handleChangeCampaignKey(event, setFieldValue);
                                }}
                                onBlur={handleBlur}
                                className={
                                  errors.campaignKey 
                                    ? "block w-full px-2 py-3  rounded-md border-red-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                    : "block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                }
                                placeholder="Enter Campaign Key"
                              />
                              {touched.campaignKey && errors.campaignKey && (
                                <div className="text-sm text-red-700 ">
                                  {errors.campaignKey}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 pb-5">
                        <h4 className=" flex text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                          Associated projects and environments{" "}
                          <span className="pl-1">
                            <QuestionMarkCircleIcon className="pt-2 h-8 w-8" />
                          </span>
                        </h4>
                        <div className="border p-5 my-4">
                          <div className="grid grid-cols-2 gap-10">
                            <div className="col-span-2">
                              <div className="flex flex-col pb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                  Project
                                </label>
                                <div className="relative mt-1 rounded-md shadow-sm">
                                  <Field
                                    as="select"
                                    name="projectName"
                                    disabled={campaignId ? true : false}
                                    value={values.projectName}
                                    onChange={(event) => {
                                      handleChangeProjectName(
                                        event,
                                        setFieldValue
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    className={
                                      errors.projectName
                                        ? "block w-full px-2 py-3  rounded-md border-red-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                        : "block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                    }>
                                    <option value="">Select</option>
                                    {projects.map(({ projectName, id }) => (
                                      <option key={id} value={projectName}>
                                        {projectName}
                                      </option>
                                    ))}
                                  </Field>
                                  {touched.projectName &&
                                    errors.projectName && (
                                      <div className="text-sm text-red-700 ">
                                        {errors.projectName}
                                      </div>
                                    )}
                                </div>
                              </div>

                              {!campaignId ? (
                                <div className="flex flex-col">
                                  <label className="block text-sm font-medium text-gray-700">
                                    Environments
                                  </label>
                                  <div className="relative mt-1 rounded-md shadow-sm">
                                    <Field
                                      name="environments"
                                      as="select"
                                      value={values.environments}
                                      onChange={(event) => {
                                        handleChangeEnvironmentName(
                                          event,
                                          setFieldValue
                                        );
                                      }}                                      
                                      onBlur={handleBlur}
                                      className={
                                        errors.environments 
                                          ? "block w-full px-2 py-3  rounded-md border-red-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                          : "block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                      }>
                                      <option value="">Select</option>
                                      {subEnv.map((e, ind) => (
                                        <option key={ind} value={e.sdk_Key}>
                                          {e.environmentName}
                                        </option>
                                      ))}
                                    </Field>
                                    {touched.environments &&
                                      errors.environments && (
                                        <div className="text-sm text-red-700 ">
                                          {errors.environments}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ) : (
                                <div className="flex flex-col">
                                  <label className="block text-sm font-medium text-gray-700">
                                    Environments
                                  </label>
                                  <div className="relative mt-1 rounded-md shadow-sm">
                                    <Field
                                      name="environments"
                                      value={values.environments}
                                      disabled={campaignId && true}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={
                                        errors.environments 
                                          ? "block w-full px-2 py-3  rounded-md border-red-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                          : "block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                      }></Field>
                                    {touched.environments &&
                                      errors.environments && (
                                        <div className="text-sm text-red-700 ">
                                          {errors.environments}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {showStep === "Variations" && (
                    <div className="min-w-0 flex-1 pb-5">
                      <h4 className="text-xl font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                        Create variations
                      </h4>
                      <div className="flex flex-row p-2 border-b-2 text-lg font-bold">
                        Variations
                      </div>
                      <div className="grid grid-cols-2 gap-10">
                        <div className="col-span-2">
                          <div className="flex justify-between mt-2 align-middle  ">
                            <label
                              htmlFor="controlPercent"
                              className="block text-md  font-medium text-gray-700 p-2">
                              Control
                            </label>
                            <div className="flex w-1/2 ">
                              <input
                                className="w-full cursor-pointer "
                                type="range"
                                min="0"
                                max="100"
                                name="controlPercent"
                                value={values.controlPercent}
                                onChange={(event) => {
                                  handleVariationsSliderChange(
                                    event,
                                    setFieldValue,
                                    -1,
                                    values
                                  );
                                }} 
                              />
                              <div className="p-2">
                                {values.controlPercent}%
                              </div>
                              {touched.controlPercent &&
                                errors.controlPercent && (
                                  <div>{errors.controlPercent}</div>
                                )}
                            </div>
                          </div>
                          <FieldArray name="variations">
                            {({ push, remove }) => (
                              <div>
                                {values.variations.map((data, index) => (
                                  <div key={index} className="">
                                    <div className="">
                                      <div className="flex justify-between mt-2 align-middle  ">
                                        {editingLabelIndex === index ? (
                                          <Field
                                            id={`variations.${index}.label`}
                                            name={`variations.${index}.label`}
                                            type="text"
                                            onBlur={() =>
                                              setEditingLabelIndex(null)
                                            }
                                            autoFocus
                                            className="block  px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                          />
                                        ) : (
                                          <label
                                            className="block  text-md font-medium text-gray-700 p-2"
                                            htmlFor={`variations.${index}.value`}>
                                            {data.label}{" "}
                                            <button
                                              type="button"
                                              onClick={() =>
                                                setEditingLabelIndex(index)
                                              }>
                                              <EditIcon />
                                            </button>
                                          </label>
                                        )}
                                        
                                        {touched.variations &&
                                          errors.variations &&
                                          errors.variations[index] &&
                                          errors.variations[index]
                                            .variationsPercent && (
                                            <div>
                                              {
                                                errors.variations[index]
                                                  .variationsPercent
                                              }
                                            </div>
                                          )}

                                        <div className="flex w-1/2  ">
                                        <input
                                          className="w-full cursor-pointer"
                                          type="range"
                                          min="0"
                                          max="100"
                                          name={`variations[${index}].variationsPercent`}
                                          value={data.variationsPercent}
                                          onChange={(event) => {
                                            handleVariationsSliderChange(
                                              event,
                                              setFieldValue,
                                              index,
                                              values
                                            );
                                          }} 
                                        />
                                          <div className="p-2">
                                            {data.variationsPercent}%
                                          </div>
                                          {index > 0 && (
                                            <button
                                              className="items-center justify-center rounded-md border border-transparent bg-red-500 px-2  text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2  h-6 m-2"
                                              type="button"
                                              onClick={() => remove(index)}>
                                              X
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                                  <div
                                    onClick={() =>
                                      push({
                                        variationsPercent: 0,
                                        label: `Variation ${
                                          values.variations.length + 1
                                        }`,
                                      })
                                    }
                                    className="mt-2 flex items-center text-sm text-indigo-800 cursor-pointer">
                                    {/*  Heroicon name: mini/briefcase  */}
                                    <AddIcon />
                                    Add New Variations
                                  </div>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>
                    </div>
                  )}

                  {showStep === "Goals" && (
                    <div className="min-w-0 flex-1 pb-5">
                      <div className="grid grid-cols-2 gap-10">
                        <div className="col-span-2 ">
                        <h4 className="text-xl font-bold leading-7 text-gray-900  sm:truncate sm:text-2xl sm:tracking-tight">
                       Goals
                      </h4>                        
                        <FieldArray name="goals">
                            {({ push, remove }) => (
                              <div>
                                {values.goals.map((data, index) => (
                                  <div key={index} className="  my-2">
                                    <div className="flex w-full">
                                      <div className="m-1 w-2/5">
                                        <div className="flex align-middle  ">
                                          <Field
                                            id={`goals.${index}.goalIdentifier`}
                                            name={`goals.${index}.goalIdentifier`}
                                            type="text"
                                            placeholder="Goal Identifier"
                                            onChange={(e) => {
                                              const lowercaseValue = e.target.value.toLowerCase();
                                              setFieldValue(
                                                `goals.${index}.goalIdentifier`,
                                                lowercaseValue
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            className="w-full py-2 px-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                          />
                                        </div>
                                        {errorsVisible &&
                                          errors.goals &&
                                          errors.goals[index] &&
                                          errors.goals[index].goalIdentifier && (
                                            <div className="text-red-500">
                                              {errors.goals[index].goalIdentifier}
                                            </div>
                                          )}
                                        </div>
                                      <div className="m-1 w-3/5 ">
                                        <div className="align-middle">
                                          <Field
                                          id={`goals.${index}.goalTitle`}
                                          name={`goals.${index}.goalTitle`}
                                          type="text"
                                          placeholder="Goal Title"
                                          onChange={(e) => {
                                            const lowercaseValue = e.target.value
                                            setFieldValue(
                                              `goals.${index}.goalTitle`,
                                              lowercaseValue
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          className="  w-full py-2 px-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                        />
                                        {errorsVisible &&
                                          errors.goals &&
                                          errors.goals[index] &&
                                          errors.goals[index].goalTitle && (
                                            <div className="text-red-500">
                                              {errors.goals[index].goalTitle}
                                            </div>
                                          )}
                                        </div>
                                      </div> 
                                    {values.goals.length > 1  && (
                                      <button
                                        className="items-center justify-center rounded-md border border-transparent bg-red-500 px-1 h-8 mt-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
                                        type="button"
                                        onClick={() => remove(index)}>
                                        Remove
                                      </button>
                                    )}
                                    </div>
                                  </div>
                                ))}
                                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                                  <div
                                    onClick={() =>
                                      push({
                                        goalTitle: "",
                                        goalIdentifier: "",
                                        goalInput: `Goal ${
                                          values.goals.length + 1
                                        }`,
                                      })
                                    }
                                    className="mt-2 flex items-center text-sm text-indigo-800 cursor-pointer">
                                    <AddIcon />
                                    Add New Goal
                                  </div>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>
                    </div>
                  )}

                  {showStep === "Others" && (
                    <>
                      <div className="min-w-0 flex-1 pb-5">
                        <h4 className="text-xl font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                          Traffic Split
                        </h4>
                        <div className="  mt-2 align-middle">
                          <label
                            htmlFor="trafficSplit"
                            className="block text-sm  font-medium text-gray-700 py-1">
                            Select percentage of traffic you want to include in
                            the campaign :-
                          </label>
                          <div className="py-2 pb-0">
                            <input
                              className="w-full cursor-pointer "
                              type="range"
                              min={0}
                              max={100}
                              name="trafficSplit"
                              value={values.trafficSplit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="text-center">{values.trafficSplit}%</div>
                          {touched.trafficSplit && errors.trafficSplit && (
                            <div>{errors.trafficSplit}</div>
                          )}
                        </div>
                        <div className="grid grid-cols-2 gap-10 mt-5">
                          <div className="col-span-2">
                            <label className="flex text-sm font-medium text-gray-700">
                              Google Api Key
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <Field
                                type="text"
                                name="gaApiKey"
                                placeholder="Enter api key to enable google analytics"
                                value={values.gaApiKey}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="min-w-0 flex-1 pb-5 mt-5">
                        <h4 className="text-xl font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                          Advanced AB Testing
                        </h4>
                        <div className="col-span-2 py-1 my-3">
                          <div className="flex justify-start align-middle">
                            <div className="flex items-center">
                              <input
                                id="multibanded-radio"
                                type="checkbox"
                                value="multi-banded"
                                name="abTestType"
                                checked={values.abTestType === "multi-banded"} 
                                onChange={() =>
                                  setFieldValue(
                                    "abTestType",
                                    values.abTestType === "multi-banded"
                                      ? "traditional"
                                      : "multi-banded"
                                  )
                                }
                                className="w-4 h-4"
                              />
                              <label htmlFor="multibanded-radio" className="block text-md font-medium text-gray-700 p-2">
                                Multi-Armed Bandit A/B Testing
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 py-1 my-3">
                          <div className="flex justify-start mt-2 align-middle">
                            <label
                              htmlFor="epsilonValue"
                              className="block text-md font-medium text-gray-700 p-2 pl-0">
                              Epsilon:
                            </label>
                            <div className="flex w-1/2 ml-3">
                              <input
                                className={`w-full cursor-pointer ${values.abTestType !== "multi-banded" ? "bg-gray-200 cursor-not-allowed" : ""}`}
                                type="range"
                                min={0.1}
                                max={0.9}
                                step={0.1}
                                name="epsilonValue"
                                value={values.epsilonValue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={values.abTestType !== "multi-banded"} // Disable if not multi-banded
                              />
                              <div className="p-2">
                                {values.epsilonValue}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    className={
                      showStep === "Projects"
                        ? "min-w-0 flex pb-5 pt-5 justify-end relative bottom-0"
                        : "min-w-0 flex pb-5 pt-5 justify-between relative bottom-0"
                    }>
                    {showStep !== "Projects" && (
                      <button
                        type="button"
                        onClick={() => {
                          if (showStep === "Others") {
                            setShowStep("Goals");
                          } else if (showStep === "Goals") {
                            setShowStep("Variations");
                          } else {
                            setShowStep("Projects");
                          }
                        }}
                        className={buttonCss}>
                        Back
                      </button>
                    )}

                    <ol className="flex justify-center items-center w-full mx-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-white-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-white-700 dark:border-white-700 sm:p-2 sm:space-x-4">
                      <li
                        className={
                          showStep === "Projects"
                            ? "flex items-center  text-blue-600"
                            : "flex items-center  "
                        }>
                        <span
                          className={
                            showStep === "Projects"
                              ? "flex items-center text-blue-600 justify-center w-5 h-5 mr-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500"
                              : "flex items-center justify-center w-5 h-5 mr-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
                          }>
                          1
                        </span>
                        Projects
                        <ArrowIcon />
                      </li>
                      <li
                        className={
                          showStep === "Variations"
                            ? "flex items-center  text-blue-600"
                            : "flex items-center  "
                        }>
                        <span
                          className={
                            showStep === "Variations"
                              ? "flex items-center text-blue-600 justify-center w-5 h-5 mr-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500"
                              : "flex items-center justify-center w-5 h-5 mr-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
                          }>
                          2
                        </span>
                        Variations
                        <ArrowIcon />
                      </li>
                      <li
                        className={
                          showStep === "Goals"
                            ? "flex items-center  text-blue-600"
                            : "flex items-center  "
                        }>
                        <span
                          className={
                            showStep === "Variations"
                              ? "flex items-center text-blue-600 justify-center w-5 h-5 mr-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500"
                              : "flex items-center justify-center w-5 h-5 mr-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
                          }>
                          3
                        </span>
                        Goals
                        <ArrowIcon />
                      </li>
                      <li
                        className={
                          showStep === "Others"
                            ? "flex items-center  text-blue-600"
                            : "flex items-center  "
                        }>
                        <span
                          className={
                            showStep === "Others"
                              ? "flex items-center text-blue-600 justify-center w-5 h-5 mr-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500"
                              : "flex items-center justify-center w-5 h-5 mr-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
                          }>
                          4
                        </span>
                        Others
                      </li>
                    </ol>

                    {showStep === "Projects" && (
                      <button
                        type="button"
                        disabled={
                          !values.campaignKey ||
                          !values.projectName ||
                          !values.environments ||
                          !isCampaignKey
                        }
                        onClick={()=>setShowStep("Variations")}
                        className={buttonCss}>
                        Next
                      </button>
                    )}
                    {showStep === "Variations" && (
                      <button
                        type="button"
                        onClick={() => setShowStep("Goals")}
                        className={buttonCss}>
                        Next
                      </button>
                    )}
                   
                    {showStep === 'Goals' && (
                      <button
                        type="button"
                        onClick={() => {
                          setErrorsVisible(true);
                          const hasErrors = Object.keys(errors).length > 0;
                          if (!hasErrors) {
                            setShowStep('Others');
                          }
                        }}
                        className={buttonCss}>
                        Next
                      </button>
                    )}
                    {showStep === "Others" && (
                      <button type="submit" className={buttonCss}>
                        {campaignId ? "Update" : "Create"}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-1 relative bg-gray-200 p-5 min-h-[calc(100vh-170px)]">
                <h3 className="text-lg font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                  A/B Testing Platform
                </h3>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="my-2 flex-shrink text-sm text-gray-500">
                    <ul className="list-disc px-5">
                      <li className="pb-2">
                       Remember Campaign Key of every campaign should be unique.
                      </li>
                      <li className="pb-2">
                        Implementation directly on the server allows you to run
                        more sophisticated tests.
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 className="text-lg font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                  What is Multi-Armed Bandit A/B Testing?
                </h3>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="my-2 flex-shrink text-sm text-gray-500">
                    <ul className="list-disc px-5">
                      <li className="pb-2">
                      A method for dynamically allocating traffic to different variations (arms) of a test to maximize the overall reward (e.g., conversion rate).
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 className="text-lg font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                 Formula for Epsilon-Greedy Algorithm
                </h3>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="my-2 flex-shrink text-sm text-gray-500">
                    <ul className="list-disc px-5">
                      <li className="pb-2">
                        Epsilon (ε) : Probability of exploring a random action.        
                      </li>
                      <li className="pb-2">
                        1 - Epsilon (1 - ε) : Probability of exploiting the current best action.        
                      </li>
                      <li className="pb-2">
                        Formula :    Action=(Random action with probability ϵ  )
                                    (Best known action with probability 1−ϵ )  
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default Projects2;
