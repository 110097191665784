import { Link } from "react-router-dom";
import BagIcon from "../../icons/BagIcon";
import React from "react";

const NewCampaign = () => {

  return (
    <React.Fragment>
<div className="grid bg-gray-100 p-5 pt-20">
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Campaigns
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
           <BagIcon/>
            Build campaigns to manage your testing and rollouts
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        <span className="sm:ml-3">
          
        </span>
        <div className="relative ml-3 sm:hidden">
          <button type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            id="mobile-menu-button" aria-expanded="false" aria-haspopup="true">
            More
            <svg className="-mr-1 ml-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd" />
            </svg>
          </button>
          <div
            className="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="mobile-menu-button" tabIndex="-1">
            <Link  className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1"
              id="mobile-menu-item-0">Edit</Link>
            <Link  className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1"
              id="mobile-menu-item-1">View</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div className="grid grid-cols-3">
        <div className="col-span-2 p-5">
          <div className="flex flex-col min-h-[calc(100vh-210px)] items-center justify-center">
            <h4 className="text-2xl font-bold tracking-tight text-gray-900 py-2">No Campigns have been created yet.
            </h4>
           
              <Link
                to="/ab-testing/create"
                className="items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                Create your First Campaign.
              </Link>
            
          </div>
        </div>
        <div className="col-span-1 relative bg-gray-200 p-5 min-h-[calc(100vh-170px)]">
        <div className="block pb-5">
          <div className="relative mt-1 rounded-md shadow-sm"><input type="search" name="Search"
            className="block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Search" />
          </div>
        </div>
        <div className="block pb-5">
        <label  className="block text-sm font-medium text-gray-700">Sort By</label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <select id="currency" name="currency" className="block w-full px-2 py-3 rounded-md border-gray-300 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <option>Select Project Name</option>
            <option>Create Time</option>
            <option>Project Time</option>
            <option>Creator Time</option>
            <option>Modification Time</option>
            </select>
          </div>
        </div>
        <div className="block pb-5">
        <label  className="block text-sm font-medium text-gray-700">Filter by Languages</label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <select id="currency" name="currency" className="block w-full px-2 py-3 rounded-md border-gray-300 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <option>Select Project Name</option>
            <option>Node Js</option>
            <option>Python</option>
            <option>Php</option>
            <option>Java</option>
            </select>
          </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewCampaign;
