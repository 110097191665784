import moment from "moment/moment";

export const formatDate = (date) => {
  return moment(date).format("MMMM Do YYYY");
};

export const removeWhiteSpaceAndLowerCase = (inputString) => {
  // Use a regular expression to replace all white spaces with an empty string
  // and then convert the string to lowercase
  return inputString.replace(/\s/g, "").toLowerCase();
};
