import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import AddIcon from "../../icons/AddIcon";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../apiEndpoints";

const Table = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = async () => {
    try{
      let result = await axiosInstance.get("users/getAllUsers");
      setUsers(result.data.data);
      setLoading(false);
    }catch(err)
    {
      console.log(err)
    }
  };

  const handleDeleteClick = async () => {
    let params={
      status:"inactive"
    }
    try{
      await axiosInstance.put(`users/updateUser/${userId}`,params);
      getUsersData();
      setIsOpen(false);
    }catch(err)
    {
      console.log(err)
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <div
        className={`fixed inset-0 flex items-center justify-center z-10 ${
          isOpen ? "visible" : "invisible"
        }`}>
        <div className="fixed inset-0 bg-black  opacity-20" />
        <div className="modal-content bg-white w-1/3 shadow-custom rounded-lg p-6 relative">
          <h2 className="text-2xl font-bold mb-4">
            Are you sure you want to delete?
          </h2>
          <div className="modal-actions flex justify-end">
            <button
              className="modal-btn cursor-pointer bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
              onClick={handleDeleteClick}>
              Delete
            </button>
            <button
              className="modal-btn cursor-pointer bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
              onClick={() => setIsOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="grid  p-2 pt-20">
        <div className="grid bg-gray-100 p-5 ">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Users
              </h2>
              <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                
              </div>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
              <span className="sm:ml-3"></span>
              <div className="relative ml-3 sm:hidden">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  id="mobile-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true">
                  More
                  <svg
                    className="-mr-1 ml-2 h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  className="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="mobile-menu-button"
                  tabIndex="-1">
                  <Link
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="mobile-menu-item-0">
                    Edit
                  </Link>
                  <Link
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="mobile-menu-item-1">
                    View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => handleSearch(e)}
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 "
                    placeholder="Search"
                    required=""
                  />
                </div>
              </form>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <button
                onClick={() => navigate("/users/create")}
                type="button"
                className="flex items-center justify-center text-black bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 ">
                <AddIcon />
                Add User
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-white-800 ">
              <thead className="text-xs text-white-700 uppercase bg-gray-300 ">
                <tr>
                  <th className="px-4 py-3">Full Name</th>

                  <th scope="col" className="px-4 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Role
                  </th>
                  <th scope="col" className="px-4 py-3"></th>

                  <th scope="col" className="px-4 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>

              {users
                ?.filter((val) => {
                  if (search === "") {
                    return val;
                  } else if (
                    val.fullname.toLowerCase().includes(search.toLowerCase()) ||
                    val.email.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return val;
                  }
                })
                ?.map((ele) => {
                  return (
                    <tbody key={ele.id}>
                      <tr className="border-b ">
                        <th
                          scope="row"
                          className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap ">
                          {ele.fullname.charAt(0).toUpperCase() +
                            ele.fullname.slice(1)}{" "}
                        </th>
                        <td className="px-4 py-3">{ele.email.toLowerCase()}</td>
                        <td className="px-4 py-3">{ele.role}</td>
                        <td
                          onClick={() => {
                            navigate(`/users/${ele.id}`);
                          }}
                          className="px-4 py-3 text-sm cursor-pointer font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none">
                          Edit
                        </td>

                        <td
                          onClick={() => {
                            setIsOpen(true);
                            setUserId(ele.id);
                          }}
                          className="px-4 py-3   justify-end inline-flex items-center cursor-pointer p-0.5 text-sm font-medium text-center text-red-500 hover:text-gray-800 rounded-lg focus:outline-none">
                          Delete
                        </td>
                       
                      </tr>
                    </tbody>
                  );
                })}
               
            </table>
          </div>
          {users && users?.filter(val => {
            if (search === "") {
              return val;
            } else if (val.fullname.toLowerCase().includes(search.toLowerCase()) || val.email?.toLowerCase().includes(search.toLowerCase()) ) {
              return val;
            }
          }).length === 0 && 
          <div className="flex items-center justify-center h-64">
          <div className="text-gray-500">
           
            <h3 className="text-lg text-center font-semibold">No data found</h3>
            <p className="mt-2 text-center">Please refine your search criteria.</p>
          </div>
        </div>
        
        }       
        </div>
      </div>
    </React.Fragment>
  );
};

export default Table;
