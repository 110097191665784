import axiosInstance from "../apiEndpoints"

export const createProject = async (data) => {
    try{
        const res = await axiosInstance.post("project/createProject", data);
        return res;
    }catch(err)
    {
        console.log(err)
    }
}

export const updateProject = async (projectId, data) => {
    try{
        const res = await axiosInstance.put(`project/updateProject/${projectId}`, data);
        return res;
    }catch(err)
    {
      console.log(err)
    }
}
